import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { nanoid } from 'nanoid';
import Switch from "react-switch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { categories, groceriesObjectsList } from './initialData';
import { filterIdsToArray, entryExists, filterEmptyCagetories } from './helper/helpers';
import { CategoryDropdown } from './components/CategoryDropdown';
import packageJson from '../package.json';
import './App.scss';

function App() {
  const appVersion = packageJson.version
  // const appVersion = 0.13;
  const localStorageVersion = JSON.parse(localStorage.getItem('appVersion'));

  const initialValues = [];
  const [selectedGroceries, setSelectedGroceries] = useState(JSON.parse(localStorage.getItem('selectedGroceries')) || initialValues);
  const [allGroceries, setAllGroceries] = useState(JSON.parse(localStorage.getItem('allGroceries')) || groceriesObjectsList);

  const [inputText, setInputText] = useState('');
  const [warningText, setWarningText] = useState('');
  const [isEditModeOn, setIsEditModeOn] = useState(false);

  const editRef = useRef(null);

  const [selectedOption, setSelectedOption] = useState({value: categories[0] || "Lebensmittel"});

  const handleSetSelectedOption = useCallback((el) => {
    setSelectedOption(el)
  }, [])

  const removeItemFromSelected = useCallback((item) => {
    setSelectedGroceries(selectedGroceries.filter(el => el.id !== item.id));
    setWarningText('')
    // setAllGroceries(prevState => [...prevState, item]);
  }, [selectedGroceries]);

  const addItemToSelected = useCallback((item) => {
    // console.log("adding " + item.label)
    if (filterIdsToArray(selectedGroceries).includes(item.id)) {
      return null
    }

    setSelectedGroceries(prevState => [...prevState, item]);
    setWarningText('')
    // setAllGroceries(allGroceries.filter(el => el !== item));
  }, [selectedGroceries]);

  const removeItemFromGroceries = useCallback((item) => {
    setAllGroceries(allGroceries.filter(el => el.id !== item.id));
    if (filterIdsToArray(selectedGroceries).includes(item.id)) {
      removeItemFromSelected(item)
    }
  }, [allGroceries, selectedGroceries, removeItemFromSelected]);

  useEffect(() => {
    if(!localStorageVersion || localStorageVersion < appVersion) {
      alert("Deine App hat ein Update bekommen, deine eingetragenen Daten wurden evtl. geändert oder entfernt.");
      // localStorage.clear();  
      
      localStorage.removeItem('allGroceries');
      // localStorage.removeItem('selectedGroceries');
      localStorage.setItem('allGroceries', JSON.stringify(groceriesObjectsList));
      localStorage.setItem('appVersion', JSON.stringify(appVersion));
      window.location.reload();
    } else {
      localStorage.setItem('allGroceries', JSON.stringify(allGroceries));
      localStorage.setItem('selectedGroceries', JSON.stringify(selectedGroceries));
    }
  }, [allGroceries, selectedGroceries, localStorageVersion, appVersion]);

  const renderSelected = useMemo(() => {
    if (selectedGroceries?.length <= 0) {
      return <ul className="grocery-list grocery-list--empty">Füge etwas aus der Liste von unten zu deinem Einkaufszettel hinzu.</ul>
    }

    return <>
      {
        filterEmptyCagetories(selectedGroceries).map(category => {
          return <div key={`selectedGroceries_${category}`} className="buy-section__area">
            <h4>{category.replace(/_/g, ' ')}</h4>
            <ul className="grocery-list">
              {
                selectedGroceries.filter((el) => el.category === category).sort((a, b) => a.label.localeCompare(b.label)).map(el => {
                  return <li key={el.id} className={`grocery-item grocery-item--selected`} onClick={() => removeItemFromSelected(el)}>
                    <span>
                      {el.label}
                    </span>
                  </li>
                })
              }
            </ul>
          </div>
        })
      }
    </>

  }, [selectedGroceries, removeItemFromSelected]);

  const renderGroceries = useMemo(() => {
    if (!allGroceries) return <div></div>

    return <>
      {
        filterEmptyCagetories(allGroceries).map(category => {
          return <div key={`allGroceries_${category}`} className="buy-section__area">
            <h4>{category.replace(/_/g, ' ')}</h4>
            <ul className="grocery-list">
              {
                allGroceries.filter((el) => el.category === category).sort((a, b) => a.label.localeCompare(b.label)).map(el => {
                  return <li key={el.id} className={`grocery-item ${filterIdsToArray(selectedGroceries).includes(el.id) ? 'grocery-item--disabled' : ''} ${isEditModeOn ? 'grocery-item--edit' : ''}`} onClick={() => addItemToSelected(el)}>
                    <span>
                      {el.label}
                    </span>
                    {isEditModeOn &&
                      <span className="grocery-item__remove" onClick={() => removeItemFromGroceries(el)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </span>
                    }
                  </li>
                })
              }
            </ul>
          </div>
        })
      }
    </>
  }, [allGroceries, addItemToSelected, isEditModeOn, removeItemFromGroceries, selectedGroceries]);

  const addNewElementToAllGroveries = () => {

    if (!inputText) {
      setWarningText("Bitte gib einen Namen ein.")
      return null
    }

    if (!selectedOption) {
      setWarningText("Bitte wähle eine Kategorie.")
      return null
    }

    const findEl = (item) => {
      return allGroceries.find(el => el.label === item)
    }

    if (entryExists(inputText, allGroceries)) {
      setWarningText(`❗ ${inputText} ist schon in ${findEl(inputText).category.replace(/_/g, ' ')} vorhanden.❗`)
      setInputText('');
      return null
    }

    else {
      const updatedGroceries = [
        ...allGroceries,
        {
          id: nanoid(),
          label: inputText,
          category: selectedOption.value
        }
      ];
      setAllGroceries(updatedGroceries);
      setInputText('');
      setWarningText('')
    }
  }

  useEffect(() => {
    if(isEditModeOn) {
      editRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [isEditModeOn]);

  return (
    <div className="app">
      <header>
        Einkaufszettel
      </header>
      <div className="buy-section">
        {renderSelected}
      </div>
      <header>
        Alle Lebensmittel
      </header>
      <div className="allgroceries">
        {renderGroceries}
      </div>
      {isEditModeOn && <div className="edit-area" ref={editRef}>
        <h5>Fehlt was? Einfach hier hinzufügen:</h5>
        <input type="text" placeholder="name" value={inputText} onChange={e => setInputText(e.target.value)} />
        <CategoryDropdown className="edit-area__dropdown" selectedOption={selectedOption} setSelectedOption={handleSetSelectedOption} />
        <button className="btn" onClick={addNewElementToAllGroveries} >Hinzufügen</button>
        <div className="warning-text">{warningText}</div>
      </div>}
      <footer>
        <span>
          v{appVersion}
        </span>
        <span>
          <label className="editmode">
            <span>Editiermodus: </span>
            <Switch className="editmode__switch" onChange={() => setIsEditModeOn(prev => !prev)} checked={isEditModeOn} />
          </label>
        </span>
        <span>
          ❤️
        </span>
      </footer>
    </div>
  );
}

export default App;