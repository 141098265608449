import { nanoid } from 'nanoid';

export const categories = ["Obst_und_Gemüse", "Lebensmittel", "Kühlregal", "Fleisch", "Haushalt", "Körperpflege", "Getränke", "Süßwaren", "Diverses"].sort((a, b) => a.localeCompare(b));

export const groceriesObjectsList = [
  // Lebensmittel
  {
    id: nanoid(),
    label: 'Nudeln',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Haferflocken',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Spaghetti',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Dosentomaten',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Brötchen',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Oliven',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Tomatenmark',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Balsamico',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Sauce Holondaise',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Olivenöl',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Mayo',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Ketchup',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Zucker',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Mehl',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Ahornsirup',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Croissants',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Erdnussbutter',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Gemüsebrühe',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Gewürze',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Kokosmilch',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Mais',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Rapsöl',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Reis',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Rohrzucker',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Salz',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Sauerkraut',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Toast',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Wraps',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Burgersauce',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Honig',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Linsen',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Maggifix',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Pitabrot',
    category: 'Lebensmittel'
  },
  {
    id: nanoid(),
    label: 'Suppe',
    category: 'Lebensmittel'
  },
   // Obst_und_Gemüse
   {
    id: nanoid(),
    label: 'Möhren',
    category: 'Obst_und_Gemüse'
  },
   {
    id: nanoid(),
    label: 'Aubergine',
    category: 'Obst_und_Gemüse'
  },
   {
    id: nanoid(),
    label: 'Bohnen',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Zuchhini',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Tomaten',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Gurke',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Salat',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Paprika',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Knoblauch',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Zwiebeln',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Kartoffeln',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Äpfel',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Avocado',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Blumenkohl',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Champignons',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Erdbeeren',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Ingwer',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Kürbis',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Pfirsiche',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Spargel',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Süßkartoffeln',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Zitrone',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Bananen',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Blaubeeren',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Nektarinen',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Mango',
    category: 'Obst_und_Gemüse'
  },
  {
    id: nanoid(),
    label: 'Melone',
    category: 'Obst_und_Gemüse'
  },
  // Kühlregal
  {
    id: nanoid(),
    label: 'Parmesan',
    category: 'Kühlregal'
  },
  {
    id: nanoid(),
    label: 'Sahne',
    category: 'Kühlregal'
  },
  {
    id: nanoid(),
    label: 'Käse',
    category: 'Kühlregal'
  },
  {
    id: nanoid(),
    label: 'Joghurt',
    category: 'Kühlregal'
  },
  {
    id: nanoid(),
    label: 'Milch',
    category: 'Kühlregal'
  },
  {
    id: nanoid(),
    label: 'Mozarella',
    category: 'Kühlregal'
  },
  {
    id: nanoid(),
    label: 'Eier',
    category: 'Kühlregal'
  },
  {
    id: nanoid(),
    label: 'Butter',
    category: 'Kühlregal'
  },
  {
    id: nanoid(),
    label: 'Reibekäse',
    category: 'Kühlregal'
  },
  {
    id: nanoid(),
    label: 'Schmand',
    category: 'Kühlregal'
  },
  {
    id: nanoid(),
    label: 'Schupfnudeln',
    category: 'Kühlregal'
  },
  {
    id: nanoid(),
    label: 'Pizza',
    category: 'Kühlregal'
  },
  {
    id: nanoid(),
    label: 'Kräuterbaguette',
    category: 'Kühlregal'
  },
  {
    id: nanoid(),
    label: 'Garnelen',
    category: 'Kühlregal'
  },
  {
    id: nanoid(),
    label: 'Eis',
    category: 'Kühlregal'
  },
  {
    id: nanoid(),
    label: 'Nudeln-fertig',
    category: 'Kühlregal'
  },
  {
    id: nanoid(),
    label: 'Butter',
    category: 'Kühlregal'
  },
  {
    id: nanoid(),
    label: 'Eier',
    category: 'Kühlregal'
  },
  {
    id: nanoid(),
    label: 'Feta',
    category: 'Kühlregal'
  },
  {
    id: nanoid(),
    label: 'Hafermilch',
    category: 'Kühlregal'
  },
  {
    id: nanoid(),
    label: 'Tofu',
    category: 'Kühlregal'
  },
   // Fleisch
   {
    id: nanoid(),
    label: 'Schinken',
    category: 'Fleisch'
  },
  {
    id: nanoid(),
    label: 'Burgerfleisch',
    category: 'Fleisch'
  },
  {
    id: nanoid(),
    label: 'Würstchen',
    category: 'Fleisch'
  },
  {
    id: nanoid(),
    label: 'Speck',
    category: 'Fleisch'
  },
  {
    id: nanoid(),
    label: 'Lachs',
    category: 'Fleisch'
  },
  // Haushalt
  {
    id: nanoid(),
    label: 'Klopapier',
    category: 'Haushalt'
  },
  {
    id: nanoid(),
    label: 'Klostein',
    category: 'Haushalt'
  },
  {
    id: nanoid(),
    label: 'Klebeband',
    category: 'Haushalt'
  },
  {
    id: nanoid(),
    label: 'Putzmittel',
    category: 'Haushalt'
  },
  {
    id: nanoid(),
    label: 'Müllbeutel',
    category: 'Haushalt'
  },
  {
    id: nanoid(),
    label: 'Küchenrolle',
    category: 'Haushalt'
  },
  {
    id: nanoid(),
    label: 'Taschentücher',
    category: 'Haushalt'
  },
  {
    id: nanoid(),
    label: 'Frischhaltefolie',
    category: 'Haushalt'
  },
  {
    id: nanoid(),
    label: 'Schwämme',
    category: 'Haushalt'
  },
  {
    id: nanoid(),
    label: 'Spülmaschinentabs',
    category: 'Haushalt'
  },
  {
    id: nanoid(),
    label: 'Waschmittel',
    category: 'Haushalt'
  },
  {
    id: nanoid(),
    label: 'Weichspüler',
    category: 'Haushalt'
  },
  // Körperpflege
  {
    id: nanoid(),
    label: 'Deo',
    category: 'Körperpflege'
  },
  {
    id: nanoid(),
    label: 'Q-Tips',
    category: 'Körperpflege'
  },
  {
    id: nanoid(),
    label: 'Shampoo',
    category: 'Körperpflege'
  },
  {
    id: nanoid(),
    label: 'Zahncreme',
    category: 'Körperpflege'
  },
  {
    id: nanoid(),
    label: 'Zahnbürsten',
    category: 'Körperpflege'
  },
  {
    id: nanoid(),
    label: 'Tampons',
    category: 'Körperpflege'
  },
  {
    id: nanoid(),
    label: 'Slipeinlagen',
    category: 'Körperpflege'
  },
  {
    id: nanoid(),
    label: 'Pflaster',
    category: 'Körperpflege'
  },
  // Süßwaren
  {
    id: nanoid(),
    label: 'Chips',
    category: 'Süßwaren'
  },
  {
    id: nanoid(),
    label: 'Schoki',
    category: 'Süßwaren'
  },
  {
    id: nanoid(),
    label: 'Kekse',
    category: 'Süßwaren'
  },
  {
    id: nanoid(),
    label: 'Kuchen',
    category: 'Süßwaren'
  },
  {
    id: nanoid(),
    label: 'M&Ms',
    category: 'Süßwaren'
  },
  {
    id: nanoid(),
    label: 'Smarties',
    category: 'Süßwaren'
  },
  // Getränke
  {
    id: nanoid(),
    label: 'Bier',
    category: 'Getränke'
  },
  {
    id: nanoid(),
    label: 'Cola',
    category: 'Getränke'
  },
  {
    id: nanoid(),
    label: 'Wein',
    category: 'Getränke'
  },
  {
    id: nanoid(),
    label: 'Apfelsaft',
    category: 'Getränke'
  },
  {
    id: nanoid(),
    label: 'Karamalz',
    category: 'Getränke'
  },
  {
    id: nanoid(),
    label: 'Osaft',
    category: 'Getränke'
  },
  {
    id: nanoid(),
    label: 'Wasser',
    category: 'Getränke'
  },
]


