export const filterIdsToArray = (arr) => {
  let allIdsArray = [];
  arr.forEach(el => {
    allIdsArray.push(el.id)
  })
  return allIdsArray
}

export const entryExists = (el, arr) => {
  const tmp = [];

  arr.forEach(el => {
    tmp.push(el.label);
  })

  let uniq = [...new Set(tmp)]

  if (uniq.includes(el)) {
    return true
  } else {
    return false
  }
}

export const filterEmptyCagetories = (arr) => {
  let selectedCategories = [];

  arr.forEach(el => {
    selectedCategories.push(el.category);
  })

  let uniq = [...new Set(selectedCategories)]
  return uniq
};