import { useCallback } from 'react';
import { categories } from '../initialData';

export const CategoryDropdown = ({ selectedOption, setSelectedOption, className }) => {

  const createDropdownSelections = useCallback(() => {
    let options = [];
    categories.forEach(category => {
      // console.log(category);
      options.push({
        value: category,
        label: category
      })
    })
    return options
  }, []);
  // console.log(createDropdownSelections());
  return (
    <label>
      <select value={selectedOption?.value || ""} onChange={(e) => setSelectedOption({value: e.target.value})} className={className}>
        {createDropdownSelections().map((el) => {
          console.log(el.value);
          return <option key={`dd_${el.value}`} value={el.value}>{el.label.replace(/_/g, ' ')}</option>
        })}
      </select>
    </label>
  )
}